import { motion } from 'framer-motion';
import { useRef } from 'react';

export const Intro = (): JSX.Element => {
    const nameRef = useRef<HTMLHeadingElement>(null);

    return (
        <div className="flex flex-col justify-between p-10 h-screen text-white mb-2">
            <motion.div className="flex flex-col justify-center flex-1 align-middle font-play"
                initial={{
                    opacity: 0,
                    y: '-100%'
                }}
                animate={{
                    opacity: 1,
                    y: 0,
                }}
            >
                <span className="relative">
                    <motion.h1 className="text-4xl md:text-9xl font-light font-tourney whitespace-nowrap relative w-max py-2 md:py-4" ref={nameRef}>Anthony Folino</motion.h1>
                    <motion.div className="bg-[#65B727] h-full absolute w-4/5 bottom-0 left-0"
                        initial={{
                            width: '100%',
                        }}
                        animate={{
                            width: 0
                        }}
                        transition={{
                            duration: .7
                        }}
                    ></motion.div>
                </span>
                <span className="relative w-min whitespace-nowrap pb-1">
                    <h2 className="text-xl md:text-3xl">Front End Developer</h2>
                    <motion.div className="bg-[#65B727] h-full absolute w-4/5 bottom-0 left-0"
                        initial={{
                            width: '100%',
                        }}
                        animate={{
                            width: 0
                        }}
                        transition={{
                            duration: .7,
                            delay: .2
                        }}
                    ></motion.div>
                </span>
                <span className="relative w-min whitespace-nowrap">
                    <h2 className="text-md md:text-lg light">Melbourne, Australia</h2>
                    <motion.div className="bg-[#65B727] h-full absolute w-4/5 bottom-0 left-0"
                        initial={{
                            width: '100%',
                        }}
                        animate={{
                            width: 0
                        }}
                        transition={{
                            duration: .7,
                            delay: .2
                        }}
                    ></motion.div>
                </span>

            </motion.div>
        </div>
    )
}