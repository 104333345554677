import { motion } from "framer-motion";
import { Skills } from "./Skills";
import { useEffect, useRef } from "react";

const variants = {
    hidden: {
        opacity: 0,
        y: 100,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: .1,
        }
    },
}

type Props = {
    setOverviewRef: (ref: HTMLDivElement) => void;
}

export const Overview = ({ setOverviewRef }: Props):JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setOverviewRef(ref.current!);
    }, [setOverviewRef])

    return (
        <>
            <motion.div className="text-center md:text-left md:px-28 mb-32 w-3/4 mx-auto"
                variants={variants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                ref={ref}
                id="overview"
            >
                <h1 className="text-4xl md:text-5xl whitespace-pre-wrap mb-2 font-tourney font-light">Overview</h1>
                <p className="text-md md:text-lg">Hello, I'm Anthony, a junior front-end developer who excels in creating captivating and responsive web interfaces. My passion for clean code and user-centric design drives me to transform ideas into engaging digital experiences. I'm dedicated to crafting seamless user journeys, delivering both aesthetics and functionality in every project.</p>
            </motion.div>
            <Skills />
        </>
    )
}