import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

const variants = {
    hidden: {
        opacity: 0,
        y: 100,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: .1
        }
    },
}

type Props = {
    setExperienceRef: (ref: HTMLDivElement) => void;
}

export const Experience = ({ setExperienceRef }: Props): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setExperienceRef(ref.current!);
    }, [setExperienceRef])

    return (
        <>
            <motion.div className="text-center md:text-left md:px-28 mb-20 w-3/4 mx-auto"
                variants={variants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                ref={ref}
                id="experience"
            >
                <h1 className="text-4xl md:text-5xl mb-3 font-tourney font-light">Experience</h1>
                <div className="mb-5">
                    <h2 className="text-2xl">Front-End Software Engineer</h2>
                    <h3>GetDUCK.io <br /> 2023-</h3>
                </div>
                <div>
                    <h2 className="text-2xl">Web Developer</h2>
                    <h3>Section Media <br /> 2022-23</h3>
                </div>
            </motion.div>
            <div className="bg-white h-60 w-0.5 mx-auto mb-20"></div>
        </>
    )
}
