import { motion } from 'framer-motion';

type Props = {
    projects: HTMLDivElement | null;
    experience: HTMLDivElement | null;
    overview: HTMLDivElement | null;
}

export const Header = ({ projects, experience, overview }: Props): JSX.Element => {
    const goToLink = (ref: HTMLDivElement) => {
        window.scrollTo({ top: ref.offsetTop - 70, behavior: 'smooth'})
    }

    return (
        <div className="w-full">
            <motion.div className="flex justify-center md:justify-end gap-8 p-8 absolute right-0 border-b-[1px] w-full text-sm font-bold md:text-md z-20" style={{ boxShadow: '0 -40px #0F0E23'}} initial={{ y: -50 }} whileInView={{ y: 0 }}>
                <button onClick={() => goToLink(overview as HTMLDivElement)}>
                    OVERVIEW
                </button>
                <button onClick={() => goToLink(experience as HTMLDivElement)}>
                    EXPERIENCE
                </button>
                <button onClick={() => goToLink(projects as HTMLDivElement)}>
                    PROJECTS
                </button>
            </motion.div>
        </div>
    )
}