import { motion } from "framer-motion";
import { BsCheck2 } from "react-icons/bs";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

const titleVariants = {
    hidden: {
        opacity: 0,
        x: '-100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            bounce: .5,
            delay: .1,
        }
    },
}

const workVariants = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            bounce: .5,
            delay: .2,
        }
    },
}

type Props = {
    setProjectsRef: (ref: HTMLDivElement) => void;
}

export const Projects = ({ setProjectsRef }: Props): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setProjectsRef(ref.current!);
    }, [setProjectsRef])
    
    return (
        <div className="px-12 md:px-28 pb-20 md:w-3/4 mx-auto mb-32" ref={ref} id="projects">
            <motion.h1 className="text-4xl md:text-5xl whitespace-pre-wrap mb-10 text-center font-tourney font-light"
                variants={titleVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                Personal Projects
            </motion.h1>
            <motion.div className="flex flex-col md:flex-row gap-10 justify-center"
                variants={workVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                <motion.div className="flex flex-col bg-gray-200 text-black p-5 rounded-md"
                    whileHover={{ 
                        scale: 1.1,
                        boxShadow: "0px 0px 8px rgb(255,255,255, .5)", 
                    }}
                >
                    <Link to="https://a-folino.github.io/sneaker-store/" target="_blank">
                        <div className="flex justify-between items-center">
                            <h3 className="font-bold text-lg">SneakerLand</h3>
                            <BsArrowUpRight />
                        </div>
                        <p className="mb-2">Online store front end</p>
                        <div className="flex flex-col md:flex-row md:gap-3 text-md">
                            <div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>React</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>TypeScript</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>SASS</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Redux</p>
                                </div>
                            </div>
                            <div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Fully responsive</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Custom CSS animations</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Functional cart</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Filter products by variants</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </motion.div>
                <motion.div className="flex flex-col bg-gray-200 text-black p-5 rounded-md"
                    whileHover={{ 
                        scale: 1.1,
                        boxShadow: "0px 0px 8px rgb(255,255,255, .5)", 
                    }}
                >
                <Link to="https://a-folino.github.io/luxury-cars/" target="_blank">
                    <div className="flex justify-between items-center">
                            <h3 className="font-bold text-lg">Auto Emporium</h3>
                            <BsArrowUpRight />
                        </div>
                        <p className="mb-2">Website for luxury car company</p>
                        <div className="flex flex-col md:flex-row md:gap-3 text-md">
                            <div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>React</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>TypeScript</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>SASS</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Fetching and displaying data</p>
                                </div>
                            </div>
                            <div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Fully responsive</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Custom CSS animations</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Contact form</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <BsCheck2 style={{ width: '25px', height: '25px', fill: '#65B727' }} />
                                    <p>Single Page Application</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </motion.div>
            </motion.div>
            
        </div>
    )
}