import { motion } from 'framer-motion';

const imagesVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: .2,
        }
    }
}

const variants = {
    hidden: {
        opacity: 0,
        y: 100,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
}

export const Skills = (): JSX.Element => {

    return (
        <motion.div className="flex flex-wrap md:flex-nowrap justify-center gap-4 md:gap-7 bg-slate-50 p-5 mx-6 md:mx-auto mb-32 md:w-max rounded-xl" 
            variants={imagesVariants} initial="hidden" whileInView="visible" viewport={{ once: true }}
        >
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/html.png')} alt="HTML5" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/css.png')} alt="CSS3" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/js.png')} alt="JavaScript" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/ts.png')} alt="TypeScript" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/react.png')} alt="React" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/vue.png')} alt="Vue" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/tailwind.png')} alt="TailwindCSS" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/sass.png')} alt="Sass" />
            <motion.img className="w-10 h-10 md:w-full md:h-full" variants={variants} src={require('../assets/node.png')} alt="NodeJS" />
        </motion.div>
    )
}