import { FaLinkedinIn } from 'react-icons/fa';
import { ImGithub } from 'react-icons/im';
import { MdOutlineMailOutline } from 'react-icons/md';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const StickyLinks = (): JSX.Element => {
    const spanVariants = {
        hidden: {
            background: 'transparent',
            color: 'white',
        },
        visible: {
            backgroundColor: 'white',
            color: '#0F0F23',
            padding: '10px',
            transition: {
                duration: .1,
            }
        },
    }

    return (
        <div className="flex flex-col gap-6 fixed bottom-0 p-10 z-20">
            <Link to="https://www.linkedin.com/in/anthony-folino15/" target="_blank">
                <motion.div className="flex gap-2" 
                    variants={spanVariants} 
                    initial="hidden" 
                    whileHover="visible"
                >
                    <FaLinkedinIn style={{ width: '25px', height: '25px' }} />
                </motion.div>
            </Link>
            <Link to="https://github.com/a-folino" target="_blank">
                <motion.div className="flex gap-2" 
                    variants={spanVariants} 
                    initial="hidden" 
                    whileHover="visible"
                >
                    <ImGithub style={{ width: '25px', height: '25px' }} />
                </motion.div>
            </Link>
            <Link to="mailto:anthonyfolino15@gmail.com" target="_blank">
                <motion.div className="flex gap-2" 
                    variants={spanVariants} 
                    initial="hidden" 
                    whileHover="visible"
                >
                    <MdOutlineMailOutline style={{ width: '25px', height: '25px' }} />
                </motion.div>
            </Link>
        </div>
    )
}