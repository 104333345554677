import React, { useState } from 'react';
import { Header } from './Header';
import { Intro } from './Intro';
import { Overview } from './Overview';
import { Experience } from './Experience';
import { Projects } from './Projects';
import { StickyLinks } from './StickyLinks';
import AnimatedCursor from 'react-animated-cursor';

function App() {
  const [projectsRef, setProjectsRef] = useState<HTMLDivElement | null>(null);
  const [experienceRef, setExperienceRef] = useState<HTMLDivElement | null>(null);
  const [overviewRef, setOverviewRef] = useState<HTMLDivElement | null>(null);

  return (
    <div className="font-play text-white bg-[#0F0E23] overflow-hidden">
      <Header projects={projectsRef} experience={experienceRef} overview={overviewRef} />  
      <StickyLinks />
      <Intro />
      <Overview setOverviewRef={setOverviewRef} />
      <Experience setExperienceRef={setExperienceRef} />
      <Projects setProjectsRef={setProjectsRef} />
      <AnimatedCursor 
        innerSize={ 15 }  
        outerSize={ 15 }
        color="101,183,39"
        clickables={ ['button', 'a'] }
      />
    </div>
  );
}

export default App;
